import { useState, useContext, useEffect } from "react";
import { MDBDataTableV5 } from 'mdbreact';

import {
  Tabs,
  Tab,
  Label,
  Text,
  HTMLSelect,
  FileInput,
  Card,
  Elevation,
  Button,
  Divider,
  Callout,
  H2,
  Dialog,		
  HTMLTable,		
  Intent,		
  Classes,
} from "@blueprintjs/core";

import "./index.css";

import { AppContext } from "../../context/AppContext";

import { generateUID } from "../../utils/utils";
import { Tooltip2 } from "@blueprintjs/popover2";

import { H5ADCard } from "./H5ADCard";
import { SECard } from "./SECard";
import { ZippedADBCard } from "./ZippedADBCard";

import JSZip from "jszip";
import { searchZippedArtifactdb } from "bakana";

export function LoadExplore({ setShowPanel, ...props }) {
  // clear the entire panel
  const handleClose = () => {
    setTmpLoadInputs({
      name: `explore-dataset-1`,
      format: tabSelected,
    });

    setInputOptions([]);

    setTmpStatusValid(true);
  };

  // Access App Context
  const {
    setExploreFiles,
    setPreInputFiles,
    preInputFilesStatus,
    setPreInputFilesStatus,
    showP1, 
    showP2, 
  } = useContext(AppContext);

  // what tab was selected to identify format
  const [tabSelected, setTabSelected] = useState("H5AD");

  // contains the full list of inputs
  const [exploreInputs, setExploreInputs] = useState([]);

  // is eveyrthing good?
  const [tmpStatusValid, setTmpStatusValid] = useState(true);

  // contains the tmp list of inputs so we can discard if needed
  const [tmpLoadInputs, setTmpLoadInputs] = useState({
    name: `explore-dataset-1`,
    format: tabSelected,
  });

  // final inputs for confirmation with options
  const [inputOptions, setInputOptions] = useState([]);

  // show check summary popup
  const [isOpen, setIsOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState('var'); // Default selected option

  //handle check summary popup
  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleExplore = () => {
    let mapFiles = {};
    mapFiles[tmpLoadInputs.name] = tmpLoadInputs;
    mapFiles[tmpLoadInputs.name]["options"] = inputOptions;

    let fInputFiles = { files: mapFiles };
    setExploreFiles(fInputFiles);
    setShowPanel("explore");
  };

  // final jszipnames for confirmation with options
  const [jsZipNames, setJsZipNames] = useState(null);
  const [jsZipObjs, setJSZipObjs] = useState(null);

  // making sure tmpNewInputs are valid as the user chooses datasets
  useEffect(() => {
    if (tmpLoadInputs) {
      let all_valid = true;
      let x = tmpLoadInputs;
      if (x.format === "H5AD") {
        if (x?.h5 && !x?.h5.name.toLowerCase().endsWith("h5ad")) {
          all_valid = false;
        }

        if (!x.h5) all_valid = false;
      } else if (x.format === "SummarizedExperiment") {
        if (x?.rds && !x?.rds.name.toLowerCase().endsWith("rds")) {
          all_valid = false;
        }

        if (!x.rds) all_valid = false;
      } else if (x.format === "ZippedArtifactdb") {
        if (x?.zipfile && !x?.zipfile.name.toLowerCase().endsWith("zip")) {
          all_valid = false;
        }

        if (!x.zipfile) all_valid = false;
        if (!x.zipname) all_valid = false;
      }

      setTmpStatusValid(all_valid);

      if (all_valid) {
        // currently only allow a single dataset
        tmpLoadInputs["uid"] = generateUID(tmpLoadInputs);

        setExploreInputs([tmpLoadInputs]);
      }
    }
  }, [tmpLoadInputs]);

  // send a preflight req out everytime a new dataset is added
  useEffect(() => {
    if (Array.isArray(exploreInputs) && exploreInputs.length > 0) {
      let mapFiles = {};
      for (const f of exploreInputs) {
        mapFiles[f.name] = f;
      }

      setPreInputFiles({
        files: mapFiles,
      });
    }
  }, [exploreInputs]);

  const render_inputs = () => {

    let fileNames = [];

    //check project name and load data accordanly
    if (showP1) {
      fileNames = ['ALL_etv6runx1.h5ad'];
    } else if (showP2) {
      fileNames = ['1_athero_mouse_WA.h5ad'];
    } else {
      fileNames = ['pbmc3k.h5ad'];
    }

    return (
      <Tabs
        animate={true}
        renderActiveTabPanelOnly={true}
        vertical={true}
        defaultSelectedTabId={tabSelected}
        onChange={(ntab, otab) => {
          let tmp = { ...tmpLoadInputs };
          tmp["format"] = ntab;
          setTmpLoadInputs(tmp);
          setTabSelected(ntab);

          setExploreInputs([]);
          setInputOptions([]);
          setPreInputFiles(null);
          setPreInputFilesStatus(null);
        }}
      >
        {showP1 && (
        <Tab
          id="H5AD"
          title="H5AD"
          panel={
            <div>
              <div className="row">
                <Callout intent="primary"> 
                <>               
                  <p>
                  <strong> GEPARD Project : </strong>   
                      Select Hosted datasets from <a href="https://www.era-learn.eu/network-information/networks/era-permed/1st-joint-transnational-call-for-proposals-2018/genomics-based-tools-for-personalized-treatment-to-reduce-chemotherapy-burden-in-pediatric-cancer-gepard">
                        GEPARD Project</a> consortium (Heinäniemi, Lohi, Hauer, Duque-Afonso, Wirta).
                    <ul>
                        <li>
                          ALL_etv6runx1.h5ad: ETV6-RUNX1 patient profiles (<a href="https://pubmed.ncbi.nlm.nih.gov/33218352/">
                          Mehtonen et al Genome Med 2020 </a>), 
                          explore leukemic cell transcriptome profiles at diagnosis and two weeks after therapy start
                        </li>
                        <li>
                          HCA_blineage.h5ad: Healthy bone marrow B-lineage differentiation 
                          (data from <a href="https://data.humancellatlas.org/explore/projects/cc95ff89-2e68-4a08-a234-480eca21ce79">
                          Human Cell Atlas </a>, re-analyzed in <a href="https://pubmed.ncbi.nlm.nih.gov/33218352/">
                          Mehtonen et al Genome Med 2020 </a>)
                        </li>
                        <li>
                          Pax5_preBII.h5ad: Pre-B II cell state in Pax5+/- mouse model and WT mouse (coming soon)
                        </li>
                        <li>
                          WTmouse_blineage.h5ad: Healthy bone marrow B-lineage differentiation from sorted cell populations (coming soon)
                        </li>
                        <li>
                          Pax5_leukemia.h5ad: Leukemias from Pax5+/- mouse model (coming soon)
                        </li>
                        <li>
                          Tcf3Pbx1_tissues_therapy.h5ad: Tcf3-Pbx1 leukemic cells from different tissues and during therapy (coming soon)
                        </li>
                    </ul>   
                  </p>
                </>               
                </Callout>
              </div>
              <div className="row">
                <Label className="row-input">
                  <Text className="text-100">
                    <span>Choose a H5AD file</span>
                  </Text>
                  <HTMLSelect
                  style={{
                    marginTop: '5px',
                  }}
                    onChange={async(msg) => {
                      const fileName =  msg.target.value
                      console.log(fileName)
                       if (fileName) {
                          const fileResponse = await fetch(fileName);
                          const fileBlob = await fileResponse.blob();
                          const file = new File([fileBlob], fileName);
                          setTmpLoadInputs({
                            ...tmpLoadInputs,
                            h5: file,
                          });
                        }
                    }}  
                  >
                  <option value="">Select a file</option>
                  {fileNames.map((fileName) => (
                    <option key={fileName} value={fileName}>
                      {fileName}
                    </option>
                  ))}
                  </HTMLSelect>
                </Label>
              </div>
            </div>
          }
        />
        )}
        {showP2 && (
       <Tab
       id="H5AD"
       title="H5AD"
       panel={
         <div>
           <div className="row">
             <Callout intent="primary"> 
                <>
                <p>
                <strong> scMIR Project : </strong>   
                    Select hosted datasets from scMIR Project consortium (Heinäniemi lab).
                  <ul>
                      <p>
                        <strong>Atherosclerosis disease model:</strong> explore miRNA gene expression in blood and white adipose tissue at early and late disease stages 
                        and under inflammatory challenge. (MAP-CAD consortium, Heinäniemi, Kaikkonen, Lönnberg, Roivainen; funding Academy of Finland TERVA program)
                        <li>1_athero_mouse_WA.h5ad</li>
                      </p>
    
                  </ul>   
                </p>
              </>
                </Callout>
              </div>
              <div className="row">
                <Label className="row-input">
                  <Text className="text-100">
                    <span>Choose a H5AD file</span>
                  </Text>
                  <HTMLSelect
                  style={{
                    marginTop: '5px',
                  }}
                    onChange={async(msg) => {
                      const fileName =  msg.target.value
                      console.log(fileName)
                       if (fileName) {
                          const fileResponse = await fetch(fileName);
                          const fileBlob = await fileResponse.blob();
                          const file = new File([fileBlob], fileName);
                          setTmpLoadInputs({
                            ...tmpLoadInputs,
                            h5: file,
                          });
                        }
                    }}  
                  >
                  <option value="">Select a file</option>
                  {fileNames.map((fileName) => (
                    <option key={fileName} value={fileName}>
                      {fileName}
                    </option>
                  ))}
                  </HTMLSelect>
                </Label>
              </div>
            </div>
          }
        /> 
           
        )}
      </Tabs>
    );
  };

//check summary by clicking the the button
  // const CheckSummary = () => {
  //   console.log(preInputFilesStatus)
  //   const var_arr = Object.values(preInputFilesStatus['explore-dataset-1'].all_features["columns"])
  //   const var_headers = var_arr.map(item => item.name);

  //   const obs_arr = Object.values(preInputFilesStatus['explore-dataset-1'].cells["columns"])
  //   const obs_headers = obs_arr.map(item => item.name);

  //   const genes = Object.values(preInputFilesStatus['explore-dataset-1'].all_features["_rowNames"])
  //   const cells = Object.values(preInputFilesStatus['explore-dataset-1'].cells["_rowNames"])

  //   const getTableData = (option) => {
  //     if (option === 'var') {
  //         const data = {
  //           columns: [
  //             {label: 'Gene Id',
  //             field: 'gene'},
  //             ...var_headers.map(header => (
  //               {label: header,
  //               field: header}
  //             ))
  //           ],
  //           rows: genes.map((value, index) => ({
  //             gene: value,
  //             ...var_arr.reduce((acc, item) => {
  //               acc[item.name] = item._all_[index];
  //               return acc;
  //             }, {})
  //           }))
  //         }
  //       return data;
  //     } else if (option === 'obs') {
  //         const data = {
  //           columns: [
  //             {label: 'cell Id',
  //             field: 'cell'},
  //             ...obs_headers.map(header => (
  //               {label: header,
  //               field: header}
  //             ))
  //           ],
  //           rows: cells.map((value, index) => ({
  //             cell: value,
  //             ...obs_arr.reduce((acc, item) => {
  //               acc[item.name] = item._all_[index];
  //               return acc;
  //             }, {})
  //           }))
  //         }
  //       return data;
  //     }
  //   }
    
  //   const handleOptionChange = (event) => {
  //     const newOption = event.target.value;
  //     setSelectedOption(newOption);
  //   };

  //   return (
  //     <div>
  //     <Dialog
  //       isOpen={isOpen}
  //       onClose={handleCloseDialog}
  //       title="Summary of Annotations"
  //       className="section-popup"
  //     >
  //       <div className={Classes.DIALOG_BODY}>
  //       <Callout
  //         className="section-input-item"
  //         intent="primary"
  //         title="Select Annotation type to generate summary table"
  //         icon="issue">
  //         <HTMLSelect value={selectedOption} onChange={handleOptionChange}>
  //             <option value="var">var</option>
  //             <option value="obs">obs</option>
  //         </HTMLSelect>
  //         <div style={{ maxHeight: '600px', overflowY: 'scroll' }}>
  //           <MDBDataTableV5 hover entriesOptions={[10, 20, 25]} entries={10} pagesAmount={4} data={getTableData(selectedOption)} searchTop searchBottom={false} />
  //         </div>
  //         </Callout>
  //       </div>
  //     </Dialog>
  //     </div>
  //   );
  // };

  return (
    <Card className="section" interactive={false} elevation={Elevation.ZERO}>
      <div className="section-header">
        <H2 className="section-header-title">Explore Pre-computed Results</H2>
      </div>
      <Divider />
      <div className="section-content">
        <div className="section-content-body">{render_inputs()}</div>
        <div className="section-info">
          <div className="section-inputs">
            {exploreInputs.map((x, i) => {
              if (x.format === "H5AD" && x.h5 !== null && x.h5 !== undefined) {
                return (
                  <H5ADCard
                    key={i}
                    resource={x}
                    index={i}
                    preflight={
                      preInputFilesStatus && preInputFilesStatus[x.name]
                    }
                    inputOpts={inputOptions}
                    setInputOpts={setInputOptions}
                    inputs={exploreInputs}
                    setInputs={setExploreInputs}
                    selectedFsetModality={props?.selectedFsetModality}
                    setSelectedFsetModality={props?.setSelectedFsetModality}
                  />
                );
              } else if (
                x.format === "SummarizedExperiment" &&
                x.rds !== null &&
                x.rds !== undefined
              ) {
                return (
                  <SECard
                    key={i}
                    resource={x}
                    index={i}
                    preflight={
                      preInputFilesStatus && preInputFilesStatus[x.name]
                    }
                    inputOpts={inputOptions}
                    setInputOpts={setInputOptions}
                    inputs={exploreInputs}
                    setInputs={setExploreInputs}
                    selectedFsetModality={props?.selectedFsetModality}
                    setSelectedFsetModality={props?.setSelectedFsetModality}
                  />
                );
              } else if (
                x.format === "ZippedArtifactdb" &&
                x.zipfile !== null &&
                x.zipfile !== undefined
              ) {
                return (
                  <ZippedADBCard
                    key={i}
                    resource={x}
                    index={i}
                    preflight={
                      preInputFilesStatus && preInputFilesStatus[x.name]
                    }
                    inputOpts={inputOptions}
                    setInputOpts={setInputOptions}
                    inputs={exploreInputs}
                    setInputs={setExploreInputs}
                    selectedFsetModality={props?.selectedFsetModality}
                    setSelectedFsetModality={props?.setSelectedFsetModality}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
      <Divider />
      <div className="section-footer">
        <Tooltip2 content="Cancel explore" placement="left">
          <Button
            icon="cross"
            intent={"warning"}
            large={true}
            onClick={handleClose}
            text="Cancel"
          />
        </Tooltip2>
        {/* Check summary button */}
        {/* {tmpStatusValid && tmpLoadInputs && exploreInputs.length ==1 && preInputFilesStatus &&(
          
        <Tooltip2 content="Check summary of data" placement="right">
          <Button
            icon="info-sign"
            onClick={handleOpenDialog}
            intent={"primary"}
            large={true}
            disabled={!tmpStatusValid}
            text="Check Summary"
          />
          {CheckSummary()}
        </Tooltip2>
        )} */}
        <Tooltip2 content="Explore dataset" placement="right">
          <Button
            icon="import"
            onClick={handleExplore}
            intent={"primary"}
            large={true}
            disabled={!tmpStatusValid}
            text="Explore"
          />
        </Tooltip2>
      </div>
    </Card>
  );
}
