// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logs-container {
  background-color: #000000;
  /* background-image: radial-gradient(rgba(206, 202, 202, 0.75), black 120%); */
  padding: 2rem;
  color: white;
  font-family: "Courier New", Courier, monospace;
  /* font-weight: bold; */
}

.logs-container::after {
  content: "";
  position: absolute;
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0.15) 1px,
    transparent 1px,
    transparent 2px
  );
  pointer-events: none;
}

.logs-error,
.logs-warn {
  color: #ff0000;
}

.logs-info {
  color: #86e9fe;
}

.logs-start {
  color: #ffff00;
}

.logs-success,
.logs-complete {
  color: #00ff00;
}

::selection {
  background: #bebebe;
  text-shadow: none;
}

.logs-container > pre {
  margin: 0;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/components/Logs/logs.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,8EAA8E;EAC9E,aAAa;EACb,YAAY;EACZ,8CAA8C;EAC9C,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB;;;;;;GAMC;EACD,oBAAoB;AACtB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,qBAAqB;EACrB,0BAA0B;EAC1B,sBAAsB;EACtB,wBAAwB;EACxB,qBAAqB;AACvB","sourcesContent":[".logs-container {\n  background-color: #000000;\n  /* background-image: radial-gradient(rgba(206, 202, 202, 0.75), black 120%); */\n  padding: 2rem;\n  color: white;\n  font-family: \"Courier New\", Courier, monospace;\n  /* font-weight: bold; */\n}\n\n.logs-container::after {\n  content: \"\";\n  position: absolute;\n  background: repeating-linear-gradient(\n    0deg,\n    rgba(0, 0, 0, 0.15),\n    rgba(0, 0, 0, 0.15) 1px,\n    transparent 1px,\n    transparent 2px\n  );\n  pointer-events: none;\n}\n\n.logs-error,\n.logs-warn {\n  color: #ff0000;\n}\n\n.logs-info {\n  color: #86e9fe;\n}\n\n.logs-start {\n  color: #ffff00;\n}\n\n.logs-success,\n.logs-complete {\n  color: #00ff00;\n}\n\n::selection {\n  background: #bebebe;\n  text-shadow: none;\n}\n\n.logs-container > pre {\n  margin: 0;\n  white-space: pre-wrap;\n  white-space: -moz-pre-wrap;\n  white-space: -pre-wrap;\n  white-space: -o-pre-wrap;\n  word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
