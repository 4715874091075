// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  border-collapse: separate;
  border-spacing: 3px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-container {
  padding: 10px;
  margin: 10px;
}

td {
  padding: 0 15px;
}

.td-highlight {
  font-weight: bold;
  color: #2b95d6;
  font-style: italic;
}
`, "",{"version":3,"sources":["webpack://./src/components/Plots/CellLabelTable.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":["table {\n  border-collapse: separate;\n  border-spacing: 3px;\n}\n\ntable tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.table-container {\n  padding: 10px;\n  margin: 10px;\n}\n\ntd {\n  padding: 0 15px;\n}\n\n.td-highlight {\n  font-weight: bold;\n  color: #2b95d6;\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
