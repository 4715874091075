import { useState, useContext, useEffect } from "react";
import { MDBDataTableV5 } from 'mdbreact';

import {
  Tabs,
  Tab,
  Label,
  Text,
  HTMLSelect,
  FileInput,
  Card,
  Elevation,
  Button,
  Divider,
  Callout,
  Code,
  H2,
  Checkbox,
  NumericInput,
  H5,
  Switch,
  Dialog,
  Classes,
} from "@blueprintjs/core";

import { Tooltip2 } from "@blueprintjs/popover2";

import "./index.css";

import { AppContext } from "../../context/AppContext";

import { generateUID } from "../../utils/utils";
import { v4 as uuidv4 } from "uuid";

import { MatrixMarket } from "./MatrixMarketCard";
import { ExperimentHub } from "./ExperimentHubCard";
import { TenxHDF5 } from "./TenxHDF5Card";

import { code } from "../../utils/utils";
import { H5AD } from "./H5ADCard";
import { RDSSE } from "./RDSSECard";
import { ZippedADB } from "./ZippedADBCard";

import JSZip from "jszip";
import { searchZippedArtifactdb } from "bakana";

export function NewAnalysis({ setShowPanel, setStateIndeterminate, ...props }) {
  // close the entire panel
  const handleClose = () => {
    setTmpFiles([]);
    setInputOptions([]);

    setPreInputOptionsStatus(null);
    setPreInputFilesStatus(null);

    setEhubSel("none");
  };

  const initTmpFile = () => {
    return {
      name: uuidv4(),
      format: tabSelected,
    };
  };

  // default ehub dataset selection
  const [ehubSel, setEhubSel] = useState("none");

  // Access App Context
  const {
    ehubDatasets,
    setPreInputFiles,
    preInputFilesStatus,
    setPreInputOptions,
    preInputOptionsStatus,
    setInputFiles,
    setPreInputOptionsStatus,
    setPreInputFilesStatus,
    tmpFiles,
    setTmpFiles,
    showP1, 
    showP2, 
  } = useContext(AppContext);

  // what tab was selected to identify format
  const [tabSelected, setTabSelected] = useState("ExperimentHub");

  // contains the full list of inputs
  // const [newInputs, setNewInputs] = useState([]);

  // contains batch selection
  const [batch, setBatch] = useState(null);

  // contains subset selection
  const [subset, setSubset] = useState({
    subset: null,
  });

  // final inputs for confirmation with options
  const [inputOptions, setInputOptions] = useState([]);

  // contains the tmp list of inputs so we can discard if needed
  const [tmpNewInputs, setTmpNewInputs] = useState({
    name: uuidv4(),
    format: tabSelected,
  });

  // should i enable the add button?
  const [tmpStatusValid, setTmpStatusValid] = useState(null);

  // default code for none
  const default_none = `${code}::none`;

  // final jszipnames for confirmation with options
  const [jsZipNames, setJsZipNames] = useState(null);
  const [jsZipObjs, setJSZipObjs] = useState(null);

  // show a gradient for subset selection
  const [toggleFactorsGradient, setToggleFactorsGradient] = useState(true);

  // show check summary popup
  const [isOpen, setIsOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState('var'); // Default selected option

  const handleAddDataset = () => {
    tmpNewInputs["uid"] = generateUID(tmpNewInputs);

    setTmpFiles([...tmpFiles, tmpNewInputs]);

    setEhubSel("none");

    setTmpNewInputs(initTmpFile());
  };

  const handleRunAnalysis = () => {
    if (Array.isArray(tmpFiles) && Array.isArray(inputOptions)) {
      if (tmpFiles.length !== inputOptions.length) {
        console.error("forgot to set options?");
      } else {
        let mapFiles = {};
        for (let i = 0; i < tmpFiles.length; i++) {
          let f = tmpFiles[i],
            o = inputOptions[i];

          mapFiles[f.name] = {
            ...f,
            options: o,
          };
        }

        let fInputFiles = { files: mapFiles };

        if (!(batch === "none" || batch === null)) {
          fInputFiles["batch"] = batch;
        } else {
          fInputFiles["batch"] = null;
        }

        if (!(subset["subset"] === "none" || subset["subset"] === null)) {
          fInputFiles["subset"] = {
            field: subset["subset"],
          };

          if ("values" in subset) {
            fInputFiles.subset.values = Array.from(subset.values);
          } else {
            fInputFiles.subset.ranges = [subset.chosen_minmax];
          }
        } else {
          fInputFiles["subset"] = null;
        }

        setInputFiles(fInputFiles);

        setShowPanel("results");
      }
    }
  };

  const handleRunAndParams = () => {
    if (Array.isArray(tmpFiles) && Array.isArray(inputOptions)) {
      if (tmpFiles.length !== inputOptions.length) {
        console.error("forgot to set options?");
      } else {
        setStateIndeterminate(true);

        let mapFiles = {};
        for (let i = 0; i < tmpFiles.length; i++) {
          let f = tmpFiles[i],
            o = inputOptions[i];

          mapFiles[f.name] = {
            ...f,
            options: o,
          };
        }

        let fInputFiles = { files: mapFiles };

        if (!(batch === "none" || batch === null)) {
          fInputFiles["batch"] = batch;
        } else {
          fInputFiles["batch"] = null;
        }

        if (!(subset["subset"] === "none" || subset["subset"] === null)) {
          fInputFiles["subset"] = {
            field: subset["subset"],
          };

          if ("values" in subset) {
            fInputFiles.subset.values = Array.from(subset.values);
          } else {
            fInputFiles.subset.ranges = [subset.chosen_minmax];
          }
        } else {
          fInputFiles["subset"] = null;
        }

        setInputFiles(fInputFiles);
        setShowPanel("params");
      }
    }
  };

  //handle check summary popup
  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  // making sure tmpNewInputs are valid as the user chooses datasets
  useEffect(() => {
    if (tmpNewInputs) {
      let all_valid = true;
      let x = tmpNewInputs;
      if (x.format === "MatrixMarket") {
        if (
          (x?.mtx &&
            !(
              x?.mtx.name.toLowerCase().endsWith("mtx") ||
              x?.mtx.name.toLowerCase().endsWith("mtx.gz")
            )) ||
          (x?.genes &&
            !(
              x?.genes.name.toLowerCase().endsWith("tsv") ||
              x?.genes.name.toLowerCase().endsWith("tsv.gz")
            )) ||
          (x?.annotations &&
            !(
              x?.annotations.name.toLowerCase().endsWith("tsv") ||
              x?.annotations.name.toLowerCase().endsWith("tsv.gz")
            ))
        ) {
          all_valid = false;
        }

        if (!x.mtx) all_valid = false;
      } else if (x.format === "10X") {
        if (
          x?.h5 &&
          !(
            x?.h5.name.toLowerCase().endsWith("hdf5") ||
            x?.h5.name.toLowerCase().endsWith("h5")
          )
        ) {
          all_valid = false;
        }

        if (!x.h5) all_valid = false;
      } else if (x.format === "H5AD") {
        if (x?.h5 && !x?.h5.name.toLowerCase().endsWith("h5ad")) {
          all_valid = false;
        }

        if (!x.h5) all_valid = false;
      } else if (x.format === "H5AD2") {
        if (x?.h5 && !x?.h5.name.toLowerCase().endsWith("h5ad")) {
          all_valid = false;
        }

        if (!x.h5) all_valid = false;
      }else if (x.format === "SummarizedExperiment") {
        if (x?.rds && !x?.rds.name.toLowerCase().endsWith("rds")) {
          all_valid = false;
        }

        if (!x.rds) all_valid = false;
      } else if (x.format === "ZippedADB") {
        if (x?.zipfile && !x?.zipfile.name.toLowerCase().endsWith("zip")) {
          all_valid = false;
        }

        if (!x.zipfile) all_valid = false;
      } else if (x.format === "ExperimentHub") {
        if (x?.id && !(ehubDatasets.indexOf(x?.id) !== -1)) {
          all_valid = false;
        }

        if (!x.id) all_valid = false;
      }

      setTmpStatusValid(all_valid);
    }
  }, [tmpNewInputs]);

  // send a preflight req out everytime a new dataset is added
  useEffect(() => {
    if (Array.isArray(tmpFiles) && tmpFiles.length > 0) {
      let mapFiles = {};
      let counter = 0;
      for (const f of tmpFiles) {
        f.options = inputOptions[counter];
        mapFiles[f.name] = f;
        counter++;
      }

      setPreInputFiles({
        files: mapFiles,
        batch: batch,
      });
    }
  }, [tmpFiles]);

  // compute intersection when options change
  useEffect(() => {
    if (Array.isArray(tmpFiles) && tmpFiles.length > 0 && preInputFilesStatus) {
      setPreInputOptions({
        options: inputOptions,
      });
    }
  }, [inputOptions]);


  const render_inputs = () => {
    let fileNames = [];

    //check project name and load data accordanly
    if (showP1) {
      fileNames = ['ALL_etv6runx1.h5ad'];
    } else if (showP2) {
      fileNames = ['0_tms_genes_primirnas_intragenic_062022.h5ad','0_athero_mouse_MO.h5ad','0_athero_mouse_WA.h5ad','0_TS_spleen_human.h5ad'];
    } else {
      fileNames = ['pbmc3k.h5ad'];
    }

    return (
      <Tabs
        animate={true}
        renderActiveTabPanelOnly={true}
        vertical={true}
        defaultSelectedTabId={tabSelected}
        onChange={(ntab, otab) => {
          let tmp = { ...tmpNewInputs };
          tmp["format"] = ntab;
          setTmpNewInputs(tmp);
          setTabSelected(ntab);
        }}
      >
        <Tab
          id="H5AD"
          title="New H5AD"
          panel={
            <>
              <div className="row">
                <Callout intent="primary">
                  <p>
                    Load a H5AD (<Code>*.h5ad</Code>) file containing a count
                    matrix in one of its layers. Gene annotations should be
                    present in the <Code>vars</Code>.
                  </p>
                </Callout>
              </div>
              <div className="row">
                <Label className="row-input">
                  <Text className="text-100">
                    <span>Choose a H5AD dataset</span>
                  </Text>
                  <FileInput
                    style={{
                      marginTop: "5px",
                    }}
                    text={tmpNewInputs?.h5 ? tmpNewInputs?.h5.name : ".h5ad"}
                    onInputChange={(msg) => {
                      if (msg.target.files) {
                        setTmpNewInputs({
                          ...tmpNewInputs,
                          h5: msg.target.files[0],
                        });
                      }
                    }}
                  />
                </Label>
              </div>
            </>
          }
        />
        <Tab
          id="H5AD2"
          title="Project H5AD"
          panel={
            <>
              <div className="row">
                <Callout intent="primary">
                  <p>
                    Load a predefined H5AD (<Code>*.h5ad</Code>) file containing a count
                    matrix in one of its layers from the dropdown menu.
                  </p>
                </Callout>
              </div>
              <div className="row"> 
                <Label className="row-input">
                  <Text className="text-100">
                    <span>Choose a H5AD dataset</span>
                  </Text>
                  <HTMLSelect
                  style={{
                    marginTop: '5px',
                  }}
                  onChange={async(msg) => {
                    const fileName =  msg.target.value
                     if (msg.target.value) {
                        const fileName = msg.target.value;
                        const fileResponse = await fetch(fileName);
                        const fileBlob = await fileResponse.blob();
                        const file = new File([fileBlob], fileName);
                        setTmpNewInputs({
                          ...tmpNewInputs,
                          h5: file,
                        });
                      }
                  }}
                  >
                  <option value="">Select a file</option>
                  {fileNames.map((fileName) => (
                    <option key={fileName} value={fileName}>
                      {fileName}
                    </option>
                  ))}
                  </HTMLSelect>

                </Label>
              </div>
            </>
          }
        />
      </Tabs>
    );
  };

  const getBatchColumns = () => {
    const cols = preInputFilesStatus[tmpFiles[0].name].cells["columns"];
    return Object.keys(cols).filter(
      (x) =>
        cols[x].type !== "continuous" &&
        (cols[x]["type"] === "both" ||
          (cols[x]["type"] === "categorical" && cols[x]["truncated"] === false))
    );
  };

  const getSubsetColumns = () => {
    const cols = preInputFilesStatus[tmpFiles[0].name].cells["columns"];

    return Object.keys(cols).filter((x) =>
      cols[x]["type"] === "continuous" ? true : !cols[x]["truncated"]
    );
  };

  // useEffect(() => {
  //   let tmpSubset = {};

  //   if (toggleFactorsGradient === true) {
  //     tmpSubset["values"] = [];
  //   } else {
  //     tmpSubset["minmax"] = [col.min, col.max];
  //     tmpSubset["chosen_minmax"] = [col.min, col.max];
  //   }

  //   setSubset(tmpSubset);
  // }, [toggleFactorsGradient]);

  const render_batch_correction = () => {
    return (
      <>
        <Callout
          className="section-input-item"
          intent="primary"
          title="Batch correction by annotation"
          icon="issue"
        >
          <p>
            If you upload a single dataset, you can optionally tell{" "}
            <strong>kana</strong> to perform batch correction based on batch
            assignments from a single annotation column.
          </p>
          <Label className="row-input">
            <HTMLSelect
              defaultValue={default_none}
              onChange={(e) => {
                let tmpBatch = null;
                tmpBatch = e.target.value;
                if (e.target.value === default_none) {
                  tmpBatch = null;
                }
                setBatch(tmpBatch);
              }}
            >
              <option key={default_none} value={default_none}>
                --- no selection ---
              </option>
              {getBatchColumns().map((x, i) => (
                <option key={i} value={x}>
                  {x}
                </option>
              ))}
            </HTMLSelect>
          </Label>
        </Callout>
        <Callout
          className="section-input-item"
          intent="primary"
          title="Subsetting by annotation"
          icon="issue"
        >
          <p>
            If you upload a single dataset, you can optionally tell{" "}
            <strong>kana</strong> to subset the cells based on a single
            annotation column. You can select groups of interest (for
            categorical fields) or a range of values (for continuous fields) or
            both (for continuous fields with less than 50 unique levels) to
            define a subset of cells that will be used in the downstream
            analysis. For categorical fields, only the first 50 levels are
            shown.
          </p>
          <Label className="row-input">
            <HTMLSelect
              defaultValue={default_none}
              onChange={(e) => {
                let tmpSubset = { ...subset };

                tmpSubset["subset"] = e.target.value;
                if (e.target.value === default_none) {
                  tmpSubset["subset"] = null;
                } else {
                  const col =
                    preInputFilesStatus[tmpFiles[0].name].cells["columns"][
                      tmpSubset["subset"]
                    ];

                  if (col.type === "categorical") {
                    tmpSubset["values"] = [];
                  } else if (col.type === "continuous") {
                    tmpSubset["minmax"] = [col.min, col.max];
                    tmpSubset["chosen_minmax"] = [col.min, col.max];
                  } else if (col.type === "both") {
                    setToggleFactorsGradient(true);
                    tmpSubset["values"] = [];
                  }
                }

                setSubset(tmpSubset);
              }}
            >
              <option key={default_none} value={default_none}>
                --- no selection ---
              </option>
              {getSubsetColumns().map((x, i) => (
                <option key={i} value={x}>
                  {x}
                </option>
              ))}
            </HTMLSelect>
          </Label>
          {subset?.["subset"] !== null && (
            <>
              {(() => {
                const col =
                  preInputFilesStatus[tmpFiles[0].name].cells["columns"][
                    subset["subset"]
                  ];

                if (col.type === "categorical") {
                  return (
                    <div className="subset-section">
                      {col.values.map((x) => (
                        <Checkbox
                          key={"subset-" + x}
                          checked={subset.values.includes(x)}
                          label={x}
                          inline={true}
                          onChange={(e) => {
                            let gip = { ...subset };

                            if (e.target.checked) {
                              if (!gip.values.includes(x)) gip.values.push(x);
                            } else {
                              if (gip.values.includes(x))
                                gip.values = gip.values.filter((y) => y !== x);
                            }

                            setSubset(gip);
                          }}
                        />
                      ))}
                      {col.truncated && "... and more"}
                    </div>
                  );
                } else if (col.type === "continuous") {
                  return (
                    <div className="subset-section">
                      <div className="subset-range-field">
                        <H5>from</H5>
                        <NumericInput
                          min={
                            isFinite(subset.minmax[0])
                              ? subset.minmax[0]
                              : undefined
                          }
                          max={
                            isFinite(subset.minmax[1])
                              ? subset.minmax[1]
                              : undefined
                          }
                          value={
                            isFinite(subset.chosen_minmax[0])
                              ? subset.chosen_minmax[0]
                              : undefined
                          }
                          onValueChange={(e) => {
                            let gip = { ...subset };
                            gip["chosen_minmax"][0] = e;
                            setSubset(gip);
                          }}
                        />
                      </div>
                      <div className="subset-range-field">
                        <H5>to</H5>
                        <NumericInput
                          min={
                            isFinite(subset.minmax[0])
                              ? subset.minmax[0]
                              : undefined
                          }
                          max={
                            isFinite(subset.minmax[1])
                              ? subset.minmax[1]
                              : undefined
                          }
                          value={
                            isFinite(subset.chosen_minmax[1])
                              ? subset.chosen_minmax[1]
                              : undefined
                          }
                          onValueChange={(e) => {
                            let gip = { ...subset };
                            gip["chosen_minmax"][1] = e;
                            setSubset(gip);
                          }}
                        />
                      </div>
                    </div>
                  );
                } else if (col.type === "both") {
                  return (
                    <div>
                      <Switch
                        large={false}
                        inline={true}
                        checked={toggleFactorsGradient}
                        innerLabelChecked="yes"
                        innerLabel="no"
                        label="categorical"
                        onChange={(e) => {
                          let tmpSubset = { subset: subset["subset"] };
                          if (e.target.checked) {
                            tmpSubset["values"] = [];
                          } else {
                            tmpSubset["minmax"] = [col.min, col.max];
                            tmpSubset["chosen_minmax"] = [col.min, col.max];
                          }

                          setSubset(tmpSubset);
                          setToggleFactorsGradient(e.target.checked);
                        }}
                      />
                      {toggleFactorsGradient === true && (
                        <>
                          <Divider />
                          <div className="subset-section">
                             {col.values.map((x) => (
                              <Checkbox
                                key={"subset-" + x}
                                checked={subset.values.includes(x)}
                                label={x}
                                inline={true}
                                onChange={(e) => {
                                  let gip = { ...subset };

                                  if (e.target.checked) {
                                    if (!gip.values.includes(x))
                                      gip.values.push(x);
                                  } else {
                                    if (gip.values.includes(x))
                                      gip.values = gip.values.filter(
                                        (y) => y !== x
                                      );
                                  }

                                  setSubset(gip);
                                }}
                              />
                            ))}
                            {col.truncated && "... and more"}
                          </div>
                        </>
                      )}
                      {toggleFactorsGradient === false && (
                        <>
                          <Divider />
                          <div className="subset-section">
                            <div className="subset-range-field">
                              <H5>from</H5>
                              <NumericInput
                                min={
                                  isFinite(subset.minmax[0])
                                    ? subset.minmax[0]
                                    : undefined
                                }
                                max={
                                  isFinite(subset.minmax[1])
                                    ? subset.minmax[1]
                                    : undefined
                                }
                                value={
                                  isFinite(subset.chosen_minmax[0])
                                    ? subset.chosen_minmax[0]
                                    : undefined
                                }
                                onValueChange={(e) => {
                                  let gip = { ...subset };
                                  gip["chosen_minmax"][0] = e;
                                  setSubset(gip);
                                }}
                              />
                            </div>
                            <div className="subset-range-field">
                              <H5>to</H5>
                              <NumericInput
                                min={
                                  isFinite(subset.minmax[0])
                                    ? subset.minmax[0]
                                    : undefined
                                }
                                max={
                                  isFinite(subset.minmax[1])
                                    ? subset.minmax[1]
                                    : undefined
                                }
                                value={
                                  isFinite(subset.chosen_minmax[1])
                                    ? subset.chosen_minmax[1]
                                    : undefined
                                }
                                onValueChange={(e) => {
                                  let gip = { ...subset };
                                  gip["chosen_minmax"][1] = e;
                                  setSubset(gip);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  );
                }
              })()}
            </>
          )}
        </Callout>
      </>
    );
  };
  //check summary by clicking the button
  // const CheckSummary = () => {
  //   console.log(tmpFiles)
  //   const var_arr = Object.values(preInputFilesStatus[tmpFiles[0].name].all_features["columns"])
  //   const var_headers = var_arr.map(item => item.name);

  //   const obs_arr = Object.values(preInputFilesStatus[tmpFiles[0].name].cells["columns"])
  //   const obs_headers = obs_arr.map(item => item.name);

  //   const genes = Object.values(preInputFilesStatus[tmpFiles[0].name].all_features["_rowNames"])
  //   const cells = Object.values(preInputFilesStatus[tmpFiles[0].name].cells["_rowNames"])
  //   const getTableData = (option) => {
  //     if (option === 'var') {
  //         const data = {
  //           columns: [
  //             {label: 'Gene Id',
  //             field: 'gene'},
  //             ...var_headers.map(header => (
  //               {label: header,
  //               field: header}
  //             ))
  //           ],
  //           rows: genes.map((value, index) => ({
  //             gene: value,
  //             ...var_arr.reduce((acc, item) => {
  //               acc[item.name] = item._all_[index];
  //               return acc;
  //             }, {})
  //           }))
  //         }
  //       return data;
  //     } else if (option === 'obs') {
  //         const data = {
  //           columns: [
  //             {label: 'cell Id',
  //             field: 'cell'},
  //             ...obs_headers.map(header => (
  //               {label: header,
  //               field: header}
  //             ))
  //           ],
  //           rows: cells.map((value, index) => ({
  //             cell: value,
  //             ...obs_arr.reduce((acc, item) => {
  //               acc[item.name] = item._all_[index];
  //               return acc;
  //             }, {})
  //           }))
  //         }
  //       return data;
  //     }
  //   }
    
  //   const handleOptionChange = (event) => {
  //     const newOption = event.target.value;
  //     setSelectedOption(newOption);
  //   };

  //   return (
  //     <div>
  //     <Dialog
  //       isOpen={isOpen}
  //       onClose={handleCloseDialog}
  //       title="Summary of Annotations"
  //       className="section-popup"
  //     >
  //       <div className={Classes.DIALOG_BODY}>
  //       <Callout
  //         className="section-input-item"
  //         intent="primary"
  //         title="Select Annotation type to generate summary table"
  //         icon="issue">
  //         <HTMLSelect value={selectedOption} onChange={handleOptionChange}>
  //             <option value="var">var</option>
  //             <option value="obs">obs</option>
  //         </HTMLSelect>
  //         <div style={{ maxHeight: '70vh', overflowY: 'scroll' ,marginTop: '10px',}}>
  //           <MDBDataTableV5 hover entriesOptions={[10, 20, 25]} entries={10} pagesAmount={4} data={getTableData(selectedOption)} searchTop searchBottom={false} />
  //         </div>
  //         </Callout>
  //       </div>
  //     </Dialog>
  //     </div>
  //   );
  // };

  const render_multi_summary = () => {
    return (
      <Callout
        className="section-input-item"
        intent="primary"
        title="Incorporating multiple datasets"
        icon="issue"
      >
        <p>
          If you load multiple datasets, we only use modalities that are present
          in each dataset. For each modality, we only use the intersection of
          features across datasets. This is done using the{" "}
          <em>primary feature IDs</em> for each modality, which you can
          customize manually for each dataset to improve the size of the
          intersections
          {preInputOptionsStatus &&
          Object.keys(preInputOptionsStatus).length > 0
            ? ":"
            : "."}
        </p>
        {preInputOptionsStatus &&
        Object.keys(preInputOptionsStatus).length > 0 ? (
          <ul>
            {Object.keys(preInputOptionsStatus).map((x, i) => {
              return (
                <li>
                  <Code>{x}</Code>: {preInputOptionsStatus[x]} common features
                </li>
              );
            })}
          </ul>
        ) : (
          ""
        )}
        <p>
          Each dataset is treated as a separate batch during batch correction.
          This default behavior can be changed in the analysis parameters.
        </p>
      </Callout>
    );
  };

  return (
    <Card className="section" interactive={false} elevation={Elevation.ZERO}>
      <div className="section-header">
        <H2 className="section-header-title">Explore Kana specific analysis</H2>
      </div>
      <Divider />
      <div className="section-content">
        <div className="section-content-body">
          <Callout>
          {showP1 && (
            <>
              <p>
              <strong> New H5AD : </strong>  Upload a new H5AD file from local machine.
              </p>
              <p>
              <strong> GEPARD H5AD : </strong>   
                  Select Hosted datasets from <a href="https://www.era-learn.eu/network-information/networks/era-permed/1st-joint-transnational-call-for-proposals-2018/genomics-based-tools-for-personalized-treatment-to-reduce-chemotherapy-burden-in-pediatric-cancer-gepard">
                    GEPARD Project</a> consortium (Heinäniemi, Lohi, Hauer, Duque-Afonso, Wirta).
                <ul>
                    <li>
                      ALL_etv6runx1.h5ad: ETV6-RUNX1 patient profiles (<a href="https://pubmed.ncbi.nlm.nih.gov/33218352/">
                      Mehtonen et al Genome Med 2020 </a>), 
                      explore leukemic cell transcriptome profiles at diagnosis and two weeks after therapy start
                    </li>
                    <li>
                      HCA_blineage.h5ad: Healthy bone marrow B-lineage differentiation 
                      (data from <a href="https://data.humancellatlas.org/explore/projects/cc95ff89-2e68-4a08-a234-480eca21ce79">
                      Human Cell Atlas </a>, re-analyzed in <a href="https://pubmed.ncbi.nlm.nih.gov/33218352/">
                      Mehtonen et al Genome Med 2020 </a>)
                    </li>
                    <li>
                      Pax5_preBII.h5ad: Pre-B II cell state in Pax5+/- mouse model and WT mouse (coming soon)
                    </li>
                    <li>
                      WTmouse_blineage.h5ad: Healthy bone marrow B-lineage differentiation from sorted cell populations (coming soon)
                    </li>
                    <li>
                      Pax5_leukemia.h5ad: Leukemias from Pax5+/- mouse model (coming soon)
                    </li>
                    <li>
                      Tcf3Pbx1_tissues_therapy.h5ad: Tcf3-Pbx1 leukemic cells from different tissues and during therapy (coming soon)
                    </li>
                </ul>   
              </p>
            </>
            )}
            {showP2 && (
            <>
              <p>
              <strong> New H5AD : </strong>  Upload a new H5AD file from local machine.
              </p>
              <p>
              <strong> scMIR project : </strong>   
                Select hosted datasets from scMIR project (Heinäniemi lab).
                <ul>
                  <li>
                    <strong>TMS Mouse</strong>
                    <p>- <i>0_tms_genes_primirnas_intragenic_062022.h5ad:</i> Tabula Muris Senis, explore miRNA gene expression in spleen, fat tissue, heart and aorta, liver, and marrow at different age (The Tabula Muris Consortium et al. 2020)</p>
                  </li>
                  <li>
                    <strong>TS Human</strong>
                    <p>- <i>0_TS_spleen_human.h5ad:</i> Tabula Sapiens, explore miRNA gene expression in spleen (The Tabula Sapiens Consortium et al., 2022)</p>
                  </li>
                    <li>
                      <strong>Athero mouse model</strong>
                      <p>- <i>0_athero_mouse_WA.h5ad:</i> atherosclerosis disease model, explore miRNA gene expression in fat tissue of early and late disease stage as well as in immune challenged condition
                      <br></br>
                      - <i>0_athero_mouse_MO.h5ad:</i> atherosclerosis disease model, explore miRNA gene expression in blood monocytes of early and late disease stage as well as in immune challenged condition</p>
                      
                    </li>
                </ul>   
              </p>
            </>
            )}
          </Callout>
          {tmpFiles && render_inputs()}
          <Button
            outlined={false}
            fill={true}
            intent="primary"
            icon="add"
            text="ADD DATASET"
            disabled={!tmpStatusValid}
            onClick={handleAddDataset}
          ></Button>
          
          {/* {preInputFilesStatus && tmpFiles && tmpFiles.length === 1 && (
            <>
              <Button
                outlined={false}
                fill={true}
                intent="primary"
                icon="info-sign"
                text="Check Summary"
                onClick={handleOpenDialog}
              ></Button>
                {CheckSummary()}
            </>
          )} */}
          {preInputFilesStatus && tmpFiles && tmpFiles.length === 1 && (
            <>
              <Divider />
              {render_batch_correction()}
            </>
          )}
        </div>
        <div className="section-info">
          {(tmpNewInputs.mtx || tmpNewInputs.h5 || tmpNewInputs.rds) &&
            !tmpStatusValid && (
              <Callout
                intent="danger"
                title="Incorrect file format"
                style={{
                  marginBottom: "10px",
                }}
              >
                <p>Upload files in one of these formats -</p>
                <ul>
                  <li>
                    Matrix Market - <code>*.mtx</code> or <code>*.mtx.gz</code>.
                    For features or genes, these files can be either{" "}
                    <code>*.tsv</code> or <code>*.tsv.gz</code>.
                  </li>
                  <li>
                    {" "}
                    For h5 based formats (10X or H5AD) - files must end in
                    <code>*.h5</code> or <code>*.hdf5</code> or{" "}
                    <code>*.h5ad</code>
                  </li>
                  <li>
                    {" "}
                    Summarized or single cell experiment object stored as RDS
                    files must end with <code>*.rds</code>
                  </li>
                </ul>
                Note: Names of each dataset must be unique!
              </Callout>
            )}
          {preInputOptionsStatus && tmpFiles.length > 1 && (
            <>{render_multi_summary()}</>
          )}
          <div className="section-inputs">
            {tmpFiles.map((x, i) => {
              if (x.format === "ExperimentHub") {
                return (
                  <ExperimentHub
                    key={i}
                    expand={i + 1 === tmpFiles.length}
                    resource={x}
                    index={i}
                    preflight={
                      preInputFilesStatus && preInputFilesStatus[x.name]
                    }
                    inputOpts={inputOptions}
                    setInputOpts={setInputOptions}
                    inputs={tmpFiles}
                    setInputs={setTmpFiles}
                  />
                );
              } else if (x.format === "MatrixMarket") {
                return (
                  <MatrixMarket
                    key={i}
                    expand={i + 1 === tmpFiles.length}
                    resource={x}
                    index={i}
                    preflight={
                      preInputFilesStatus && preInputFilesStatus[x.name]
                    }
                    inputOpts={inputOptions}
                    setInputOpts={setInputOptions}
                    inputs={tmpFiles}
                    setInputs={setTmpFiles}
                  />
                );
              } else if (x.format === "10X") {
                return (
                  <TenxHDF5
                    key={i}
                    expand={i + 1 === tmpFiles.length}
                    resource={x}
                    index={i}
                    preflight={
                      preInputFilesStatus && preInputFilesStatus[x.name]
                    }
                    inputOpts={inputOptions}
                    setInputOpts={setInputOptions}
                    inputs={tmpFiles}
                    setInputs={setTmpFiles}
                  />
                );
              } else if (x.format === "H5AD"  || x.format  === "H5AD2" ) {
                return (
                  <H5AD
                    key={i}
                    expand={i + 1 === tmpFiles.length}
                    resource={x}
                    index={i}
                    preflight={
                      preInputFilesStatus && preInputFilesStatus[x.name]
                    }
                    inputOpts={inputOptions}
                    setInputOpts={setInputOptions}
                    inputs={tmpFiles}
                    setInputs={setTmpFiles}
                  />
                );
              } else if (x.format === "SummarizedExperiment") {
                return (
                  <RDSSE
                    key={i}
                    expand={i + 1 === tmpFiles.length}
                    resource={x}
                    index={i}
                    preflight={
                      preInputFilesStatus && preInputFilesStatus[x.name]
                    }
                    inputOpts={inputOptions}
                    setInputOpts={setInputOptions}
                    inputs={tmpFiles}
                    setInputs={setTmpFiles}
                  />
                );
              } else if (x.format === "ZippedADB") {
                return (
                  <ZippedADB
                    key={i}
                    expand={i + 1 === tmpFiles.length}
                    resource={x}
                    index={i}
                    preflight={
                      preInputFilesStatus && preInputFilesStatus[x.name]
                    }
                    inputOpts={inputOptions}
                    setInputOpts={setInputOptions}
                    inputs={tmpFiles}
                    setInputs={setTmpFiles}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
      <Divider />
      <div className="section-footer">
        <Tooltip2 content="Clear loaded datasets" placement="left">
          <Button
            icon="cross"
            intent={"danger"}
            large={true}
            onClick={handleClose}
          >
            Clear
          </Button>
        </Tooltip2>
        {tmpFiles.length > 0 && (
          <Tooltip2 content="Run Analysis" placement="top">
            <Button
              icon="flame"
              onClick={handleRunAnalysis}
              intent={"warning"}
              large={true}
              disabled={tmpFiles.length === 0}
            >
              Analyze
            </Button>
          </Tooltip2>
        )}
        {tmpFiles.length > 0 && (
          <Tooltip2
            content="Update or modify default analysis parameters"
            placement="right"
          >
            <Button
              icon="arrow-right"
              onClick={handleRunAndParams}
              large={true}
              disabled={tmpFiles.length === 0}
            >
              Modify analysis parameters
            </Button>
          </Tooltip2>
        )}
      </div>
    </Card>
  );
}
