import { useContext , useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'; // Assuming you're using React Router for navigation

import {
  Alignment,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Classes,
  Callout,
  H2,
  Popover, 
  Menu, 
  MenuItem,
} from "@blueprintjs/core";

import pkgVersion from "../package.json";

import logo from "./assets/kana-cropped.png";
import gif from "./assets/logo.gif";

import "./App.css";

import { AnalysisMode } from "./components/AnalysisMode";
import { ExplorerMode } from "./components/ExploreMode";

import { AppContext } from "./context/AppContext";
import { resetApp } from "./utils/utils";

function App() {
  const { appMode, setAppMode, setLoadZiesel, showP1, setShowP1 , showP2, setShowP2} = useContext(AppContext);

  //new state for homepage 
  const [showHomeContent, setShowHomeContent] = useState(true);

  const resetApp = () => {
    // Add any reset logic here, if needed
    // For example: setAppMode(null);
  };

  return (
    <>
      {appMode === null && (
        <div className="App">
          <Navbar className={Classes.DARK}>
            <NavbarGroup align={Alignment.LEFT}>
              <NavbarHeading>
                <div style={{ cursor: "pointer" }} onClick={resetApp}>
                  <img height="20px" src={logo}></img>{" "}
                  <span
                    style={{
                      fontSize: "8px",
                    }}
                  >
                    v{pkgVersion.version}
                  </span>
                </div>
              </NavbarHeading>
            </NavbarGroup>
            <NavbarGroup>
              <div>
              <span>Single cell analysis in the browser</span>
              </div>
              </NavbarGroup>
          </Navbar>
          <div className="frontpage">

            {/* Home page to direct for projects */}
              {showHomeContent ? (
               <>
                  <div className="frontpage-logo">
                    <img
                      src={gif}
                      style={{ border: "5px solid #eeeeee" }}
                      width={245}
                      height={245}
                    />
                  </div>
                  <div className="frontpage-content">
                    <H2>Analyze single-cell datasets in the browser</H2>
                    <p>
                      <strong>kana</strong> uses WebAssembly and other technologies to
                      efficiently analyze large single-cell datasets in the browser.
                    </p>
                    <p>
                    Kana is a tool that allows analysis of single-cell datasets in the browser.
                    <ul>
                      <li>Always free, available and private</li>
                    </ul>  
                    </p>
                    <p>
                      Check out the {" "}
                      <a href="https://github.com/kanaverse" target="_blank">
                        GitHub page
                      </a>{" "}
                      and the{" "}
                      <a
                        href="https://doi.org/10.1101/2022.03.02.482701"
                        target="_blank"
                      >
                        bioRxiv manuscript
                      </a>{" "}
                      for more details.
                    </p>
                    <p>
                      If you want to use it with your own data, follow this {" "}
                      <a
                        target="_blank"
                        href="https://www.kanaverse.org/kana/"
                      >link.
                      </a>{" "}
                    </p>
                  </div>
                  <div className="frontpage-actions">
                    {window.navigator.userAgent.toLowerCase().indexOf("macintosh") !==
                      -1 &&
                      window.navigator.userAgent.toLowerCase().indexOf("safari") !==
                        -1 &&
                      window.navigator.userAgent.toLowerCase().indexOf("firefox") ===
                        -1 &&
                      window.navigator.userAgent.toLowerCase().indexOf("chrome") ===
                        -1 && (
                        <>
                          <Callout
                            title="For Safari users"
                            className="frontpage-rowitem-danger"
                            icon="warning-sign"
                            intent="danger"
                          >
                            <p>
                              <strong>Kana</strong> relies on some web standards that
                              are not supported by old versions of Safari. If you run
                              into any issues, try updating Safari to version 16.4 or
                              higher, or switch to Chrome or Firefox.
                            </p>
                          </Callout>
                          {window.location.href.startsWith("http://") && (
                            <Callout
                              title="Detected HTTP access"
                              className="frontpage-rowitem-danger"
                              icon="warning-sign"
                              intent="danger"
                            >
                              <p>
                                <strong>Kana</strong> does not work in HTTP mode.{" "}
                                <a href="https://kanaverse.org/kana">Click here</a> to
                                manually redirect to the HTTPS version.
                              </p>
                            </Callout>
                          )}
                        </>
                      )}
                    <p>
                    We use kana to provide access to data we generated in the projects:
                    </p>
                    <Callout
                      title="GEPARD project (ERAPERMED2018-209)"
                      onClick={() => {
                        setShowHomeContent(false);
                        setShowP1(true);
                        setShowP2(false);
                      }}
                      className="frontpage-rowitem"
                      icon="projects"
                      intent="primary"
                      style={{ cursor: "pointer" }}
                    >
                      <p>
                        Click here to direct GRPARD project.
                      </p>
                    </Callout>
                    <Callout
                      title="scMIRs project"
                      onClick={() => {
                        setShowHomeContent(false);
                        setShowP1(false);
                        setShowP2(true);
                      }}
                      className="frontpage-rowitem"
                      icon="projects"
                      intent="primary"
                      style={{ cursor: "pointer" }}
                    >
                      <p>
                      Click here to view data from scMIRs project. : MiRNA genes called pri-miRNAs are 
                      transcribed in a similar fashion that protein-coding genes. In order to quantify those, 
                      we used the annotation developed in Bouvy-Liivrand et al, 2017 and extended in Turunen et al, 
                      2021 to quantify pri-miRNA transcripts from single-cell RNA-seq datasets.
                      </p>
                    </Callout>
                  </div>
                  <div style={{ fontSize: 'x-small' }} className="frontpage-footer" >
                  The Web App is maintained by  
                    <a href="buddika.jayasingha@uef.fi" target="_blank">
                      <strong> Buddika Jayasingha </strong>
                    </a>
                    and hosted by UEF Bioinformatics center and CSC. 
                  </div>
               </>
                
              ) : (
              <>
                {/* If p1(GEPARD) */}
                {showP1 && (
                  <>
                  <div className="frontpage-logo">
                    <img
                      src={gif}
                      style={{ border: "5px solid #eeeeee" }}
                      width={245}
                      height={245}
                    />
                  </div>
                  <div className="frontpage-content">
                    <H2>Analyze single cell datasets in the browser</H2>
                    <p>
                       access data generated by 
                      <strong>{" "}
                      <a href="https://www.era-learn.eu/network-information/networks/era-permed/1st-joint-transnational-call-for-proposals-2018/genomics-based-tools-for-personalized-treatment-to-reduce-chemotherapy-burden-in-pediatric-cancer-gepard" target="_blank">
                        GEPARD project
                      </a>{" "}
                      </strong> (ERAPERMED2018-209).

                    </p>
                  </div>
                  <div className="frontpage-actions">
                    {window.navigator.userAgent.toLowerCase().indexOf("macintosh") !==
                      -1 &&
                      window.navigator.userAgent.toLowerCase().indexOf("safari") !==
                        -1 &&
                      window.navigator.userAgent.toLowerCase().indexOf("firefox") ===
                        -1 &&
                      window.navigator.userAgent.toLowerCase().indexOf("chrome") ===
                        -1 && (
                        <>
                          <Callout
                            title="For Safari users"
                            className="frontpage-rowitem-danger"
                            icon="warning-sign"
                            intent="danger"
                          >
                            <p>
                              <strong>Kana</strong> relies on some web standards that
                              are not supported by old versions of Safari. If you run
                              into any issues, try updating Safari to version 16.4 or
                              higher, or switch to Chrome or Firefox.
                            </p>
                          </Callout>
                          {window.location.href.startsWith("http://") && (
                            <Callout
                              title="Detected HTTP access"
                              className="frontpage-rowitem-danger"
                              icon="warning-sign"
                              intent="danger"
                            >
                              <p>
                                <strong>Kana</strong> does not work in HTTP mode.{" "}
                                <a href="https://kanaverse.org/kana">Click here</a> to
                                manually redirect to the HTTPS version.
                              </p>
                            </Callout>
                          )}
                        </>
                      )}
                      <Callout
                        title="I want to explore existing analysis results"
                        onClick={() => setAppMode("explore")}
                        className="frontpage-rowitem"
                        icon="geosearch"
                        intent="success"
                        style={{ cursor: "pointer" }}
                      >
                        <p>
                        We provide here pre-analyzed single-cell datasets from GEPARD project. 
                          <strong> Kana </strong>will load the existing results into the browser for further exploration.
                        </p>
                      </Callout>
                      <Callout
                        title="I want to explore dataset with Kana specific analysis"
                        onClick={() => setAppMode("analysis")}
                        className="frontpage-rowitem"
                        icon="function"
                        intent="primary"
                        style={{ cursor: "pointer" }}
                      >
                        <p>
                        Load new dataset or select existing datasets from GEPARD project and <strong> Kana </strong> will perform a standard single cell data 
                        analysis and load the results into the browser for further exploration
                        </p>
                      </Callout>
                      <Callout
                        title="I just want to try it out"
                        onClick={() => {
                          window.open('https://www.kanaverse.org/kana/', '_blank');
                        }}
                        className="frontpage-rowitem"
                        icon="clean"
                        intent="warning"
                        style={{ cursor: "pointer" }}
                      >
                        <p>
                        Try out <strong>kana</strong> with your own data – follow this link to the developer version.
                        </p>
                      </Callout>
                    </div>
                  
                </>
                
                )} 
                {/* If p2 project */}
                {showP2 && (
                  <>
                  <div className="frontpage-logo">
                    <img
                      src={gif}
                      style={{ border: "5px solid #eeeeee" }}
                      width={245}
                      height={245}
                    />
                  </div>
                  <div className="frontpage-content">
                  <H2>Analyze single cell datasets in the browser </H2>
                    <p>
                     access data generated by <strong>scMIRs project</strong>
                    </p>
                   
                  </div>
                  <div className="frontpage-actions">
                    {window.navigator.userAgent.toLowerCase().indexOf("macintosh") !==
                      -1 &&
                      window.navigator.userAgent.toLowerCase().indexOf("safari") !==
                        -1 &&
                      window.navigator.userAgent.toLowerCase().indexOf("firefox") ===
                        -1 &&
                      window.navigator.userAgent.toLowerCase().indexOf("chrome") ===
                        -1 && (
                        <>
                          <Callout
                            title="For Safari users"
                            className="frontpage-rowitem-danger"
                            icon="warning-sign"
                            intent="danger"
                          >
                            <p>
                              <strong>Kana</strong> relies on some web standards that
                              are not supported by old versions of Safari. If you run
                              into any issues, try updating Safari to version 16.4 or
                              higher, or switch to Chrome or Firefox.
                            </p>
                          </Callout>
                          {window.location.href.startsWith("http://") && (
                            <Callout
                              title="Detected HTTP access"
                              className="frontpage-rowitem-danger"
                              icon="warning-sign"
                              intent="danger"
                            >
                              <p>
                                <strong>Kana</strong> does not work in HTTP mode.{" "}
                                <a href="https://kanaverse.org/kana">Click here</a> to
                                manually redirect to the HTTPS version.
                              </p>
                            </Callout>
                          )}
                        </>
                      )}
                      <Callout
                        title="I want to explore existing analysis results"
                        onClick={() => setAppMode("explore")}
                        className="frontpage-rowitem"
                        icon="geosearch"
                        intent="success"
                        style={{ cursor: "pointer" }}
                      >
                        <p>
                        We provide here pre-analyzed single cell datasets from <strong>TERVA MAP-CAD project (atherosclerosis disease development)</strong> that include miRNA gene counts in addition to standard gene quantification.
                        </p>
                      </Callout>
                      <Callout
                        title="I want to explore dataset with Kana specific analysis"
                        onClick={() => setAppMode("analysis")}
                        className="frontpage-rowitem"
                        icon="function"
                        intent="primary"
                        style={{ cursor: "pointer" }}
                      >
                        <p>
                        Load atlas datasets that include miRNA gene counts in addition to standard gene quantification and Kana will perform a standard single cell data analvsis and load the results into the browser for further exploration
                        </p>
                      </Callout>
                      <Callout
                        title="I just want to try it out"
                        onClick={() => {
                          window.open('https://www.kanaverse.org/kana/', '_blank');
                        }}
                        className="frontpage-rowitem"
                        icon="clean"
                        intent="warning"
                        style={{ cursor: "pointer" }}
                      >
                        <p>
                        Try out <strong>kana</strong> with your own data – follow this link to the developer version.
                        </p>
                      </Callout>
                  </div>
                </>
                )}
             
                {/* {!showHomeContent && (
                // New Callout - Redirects to the existing home page
                <Callout
                  title="New Callout"
                  onClick={() => setShowHomeContent(true)}
                  className="frontpage-rowitem"
                  icon="add"
                  intent="success"
                  style={{ cursor: "pointer" }}
                >
                  <p>
                    Click here to add a new callout and return to the home page with all callouts.
                  </p>
                </Callout>
              )}   */}
              </>
              )}

            
          </div>
        </div>
      )}
      {appMode === "analysis" && <AnalysisMode />}
      {appMode === "explore" && <ExplorerMode />}
    </>
  );
}

export default App;
