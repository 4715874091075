// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-cont {
  display: flex;
  /* flex-direction: row-reverse;
    flex-wrap: wrap; */
  /* flex-flow: row-reverse wrap-reverse; */
  align-items: stretch;
  justify-content: space-evenly;
}

.gallery-elem {
  margin: 5px;
  padding: 6px;
}

.gallery-text {
  text-align: left;
}

.effect-opacity {
  opacity: 0.6;
}

.effect-grayscale {
  filter: grayscale(100%);
}

.effect-blur {
  filter: blur(3px);
}

.effect-opacitygrayscale {
  opacity: 0.6;
  filter: grayscale(100%);
}

.gitem {
  display: flex;
  flex-direction: column;
  border: 1px solid gainsboro;
  margin: 5px;
  background-color: #fff;
  box-sizing: border-box;
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  white-space: nowrap;
  outline: none;
}

.gitem-header {
  display: flex;
  padding: 3px 10px;
  justify-content: space-between;
  align-items: center;
}

.gitem-header:hover {
  cursor: grab;
}

.gitem-header-title {
  padding-right: 10px;
  color: #5F6B7C;
  font-weight: bold;
  flex-grow: 4;
  white-space: normal;
}

.gitem-content {
  display: flex;
  justify-content: center;
}

.gitem-content > .bp3-card {
  border: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Gallery/Gallery.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb;sBACoB;EACpB,yCAAyC;EACzC,oBAAoB;EACpB,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,WAAW;EACX,sBAAsB;EACtB,sBAAsB;EACtB,gEAAgE;EAChE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".gallery-cont {\n  display: flex;\n  /* flex-direction: row-reverse;\n    flex-wrap: wrap; */\n  /* flex-flow: row-reverse wrap-reverse; */\n  align-items: stretch;\n  justify-content: space-evenly;\n}\n\n.gallery-elem {\n  margin: 5px;\n  padding: 6px;\n}\n\n.gallery-text {\n  text-align: left;\n}\n\n.effect-opacity {\n  opacity: 0.6;\n}\n\n.effect-grayscale {\n  filter: grayscale(100%);\n}\n\n.effect-blur {\n  filter: blur(3px);\n}\n\n.effect-opacitygrayscale {\n  opacity: 0.6;\n  filter: grayscale(100%);\n}\n\n.gitem {\n  display: flex;\n  flex-direction: column;\n  border: 1px solid gainsboro;\n  margin: 5px;\n  background-color: #fff;\n  box-sizing: border-box;\n  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);\n  white-space: nowrap;\n  outline: none;\n}\n\n.gitem-header {\n  display: flex;\n  padding: 3px 10px;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.gitem-header:hover {\n  cursor: grab;\n}\n\n.gitem-header-title {\n  padding-right: 10px;\n  color: #5F6B7C;\n  font-weight: bold;\n  flex-grow: 4;\n  white-space: normal;\n}\n\n.gitem-content {\n  display: flex;\n  justify-content: center;\n}\n\n.gitem-content > .bp3-card {\n  border: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
